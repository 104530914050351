.normalIcon {
  color: rgba(0, 0, 0, 0.25);
}

.noList {
  list-style-type: none;
}

.application {
  color: #2185d0;
  font-weight: bold;
}

.noList > li {
  padding: 0.3em;
}
