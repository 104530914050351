@media only screen and (max-width: 750px) {
  .wrapped {
    display: block !important;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.overflowControl {
  overflow: auto;
}

.normalIcon {
  color: rgba(0, 0, 0, 0.25);
}

.errorText {
  color: rgb(255, 0, 0);
}
