.rightAlignedSort :global(.ant-table-header-column) {
  display: revert !important;
}

.rightAlignedSort :global(.ant-table-column-sorters) {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.totalGroups {
  width: 100%;
  text-align: right;
  margin: 16px 0;
  height: 32px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
}
